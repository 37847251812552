import React from "react-dom";
import './pouchfooter';
import './footer.css';

import {AlertModal} from '../helperfunctions/Helper';

import { 
    TermeScroller,   
    FooterIntro, 
    FooterSubTitle,
    TextLogin, 
    LargeSelect,
    ButtonStart, 
    BackStart,
    TextArea, 
} from './FooterStyles';

import { useRef, } from "react";
import Axios from 'axios';

function Feedbacks() {

    //alert
    const elementOutId = "modaloutalertfeedback";
    const elementDropId = "backdropoutalertfeedback";
    const buttonHandler = "ouiHandlerAlertFeedbackBtn";

    const ratingStarRef = useRef(null);
    const textAreaFeedbackRef = useRef(null);


    async function feedbackHandler(e){
        e.preventDefault();

        const validRatingStar = ratingStarRef.current.value;
        const validTextAreaFeedback = textAreaFeedbackRef.current.value;

        if(!validRatingStar){
            const pTexteRating = "Select your rating";
            AlertModal(elementOutId, elementDropId, pTexteRating, buttonHandler);
        }else if(!validTextAreaFeedback){
            const pTexteArea = "Write something to emprove Rishta";
            AlertModal(elementOutId, elementDropId, pTexteArea, buttonHandler);
        }else{
            const ratingStar = ratingStarRef.current.value;
            const textAreaFeedback = textAreaFeedbackRef.current.value;

            await Axios.post('https://rishtaapi.carions.net/feedback', {
                //withCredentials: true,
                ratingStar:ratingStar,
                textAreaFeedback:textAreaFeedback,
            }).then(() => {
                const pTexteFeedback = "Thanks for your feedback";
                AlertModal(elementOutId, elementDropId, pTexteFeedback, buttonHandler);
                document.getElementById('landing').style.display = 'none';
                document.getElementById('starter').style.display = 'block';
                document.getElementById('starterone').style.display = 'block';
                document.getElementById('starterphone').style.display = 'none';
                document.getElementById('starterlogin').style.display = 'none';
                document.getElementById('startertwo').style.display = 'none';
                document.getElementById('starterthree').style.display = 'none';
                document.getElementById('starterfour').style.display = 'none';
                document.getElementById('starterfive').style.display = 'none';
                document.getElementById('starterfiveman').style.display = 'none';
                document.getElementById('startersix').style.display = 'none';
                document.getElementById('starterseven').style.display = 'none';
                document.getElementById('starterlist').style.display = 'none';
                document.getElementById('startereight').style.display = 'none';
                document.getElementById('starternine').style.display = 'none';
                document.getElementById('terme').style.display = 'none';
                document.getElementById('feedback').style.display = 'none';
            })
        }
    }

    return ( 
        <TermeScroller id="feedback">
            <FooterSubTitle>Feedbacks</FooterSubTitle>
            <br/>
            <FooterIntro>
                <strong>Dear users</strong>,<br/>
                Rishta invites you to support us by providing suggestions and proposals that could allow 
                us to improve your user experience and develop this app.
            </FooterIntro>
            <br/>
            <TextLogin>Your rating</TextLogin>
            <div style={{display:'block',textAlign:'center'}}>
                <LargeSelect id="ratingstar" name="ratingstar" ref={ratingStarRef}>
                    <option value=''>Select your rating</option>
                    <option value='1'>⭐</option>
                    <option value='2'>⭐⭐</option>
                    <option value='3'>⭐⭐⭐</option>
                    <option value='4'>⭐⭐⭐⭐</option>
                    <option value='5'>⭐⭐⭐⭐⭐</option>
                </LargeSelect>
            </div>
            <br/>
            <TextLogin>Why this rating</TextLogin>
            <div style={{display:'block',textAlign:'center'}}>
                <TextArea id="textfeedback" ref={textAreaFeedbackRef}></TextArea>
            </div>
            <br/>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <ButtonStart id="submitfeedback" onClick={feedbackHandler}>Submit</ButtonStart>
            </div>
            <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartfooter">Back to start</button>
                </BackStart>
                <br/>
            <br/><br/>

            <div id="modaloutalertfeedback"></div>
            <div id="backdropoutalertfeedback"></div>
        </TermeScroller>
    );
}

export default Feedbacks;