window.addEventListener('load', () => {
    document.getElementById('container').addEventListener('click', containerChange);
    async function containerChange(e){
        e.preventDefault();

        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }
});