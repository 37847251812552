/* eslint-disable no-useless-escape */
import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import {AlertModal} from '../../helperfunctions/Helper';

import { 
    StarterContainer,  
    ButtonStart,  
    FormScroller,
    TextNormal,  
    BackStart, 
    LargeInput,
    LargeSelect,   
} from '../StarterStyles';

import { useRef } from "react";
import Axios from 'axios';

function StarterNine() {

    //alert
    const elementOutId = "modaloutalertstarternine";
    const elementDropId = "backdropoutalertstarternine";
    const buttonHandler = "ouiHandlerAlertStarterNineBtn";
    
    const sexenineRef = useRef(null);
    const sexefornineRef = useRef(null);
    const agenineRef = useRef(null);
    const seeknineRef = useRef(null);
    const prefernineRef = useRef(null);
    const lifenineRef = useRef(null);
    const origineRef = useRef(null);
    const contactnineRef = useRef(null);
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const countryRef = useRef(null);
    const locationRef = useRef(null);
    const emailRef = useRef(null);
    const telephoneRef = useRef(null); 
    const scorenineRef = useRef(null);


    const addUserStarter = (e) =>{
        e.preventDefault();

        //declare DOM elements properties
        const landing = document.getElementById('landing');
        const starter = document.getElementById('starter');
        const starterone = document.getElementById('starterone');
        const starterphone = document.getElementById('starterphone');
        const starterlogin = document.getElementById('starterlogin');
        const startertwo = document.getElementById('startertwo');
        const starterthree = document.getElementById('starterthree');
        const starterfour = document.getElementById('starterfour');
        const starterfive = document.getElementById('starterfive');
        const starterfiveman = document.getElementById('starterfiveman');
        const startersix = document.getElementById('startersix');
        const starterseven = document.getElementById('starterseven');
        const starterlist = document.getElementById('starterlist');
        const startereight = document.getElementById('startereight');
        const starternine = document.getElementById('starternine');
        const terme = document.getElementById('terme');
        const feedback = document.getElementById('feedback');

        //check valid email then process with setting other values
        const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        //check if input empty
        const validOrigine = origineRef.current.value;
        const validUsername = usernameRef.current.value;
        const validPassword = passwordRef.current.value;
        const validCountry = countryRef.current.value;
        const validLocation = locationRef.current.value;
        const valideremail = emailRef.current.value;

        if(!validOrigine){
            const pTexteCountry = "Please select the origin country you're from";
            AlertModal(elementOutId, elementDropId, pTexteCountry, buttonHandler);
        }else if(!validUsername){
            const pTexteUsername = "Please enter your username";
            AlertModal(elementOutId, elementDropId, pTexteUsername, buttonHandler);
        }else if(!validPassword){
            const pTextePassword = "Please enter your password";
            AlertModal(elementOutId, elementDropId, pTextePassword, buttonHandler);
        }else if(!validCountry){
            const pTexteLiveCountry = "Please enter the country you currently live";
            AlertModal(elementOutId, elementDropId, pTexteLiveCountry, buttonHandler);
        }else if(!validLocation){
            const pTexteCity = "Please enter the city you currently live";
            AlertModal(elementOutId, elementDropId, pTexteCity, buttonHandler);
        }else if(isValidEmail.test(valideremail)){
            const sexeNine = sexenineRef.current.value;
            const sexeForNine = sexefornineRef.current.value;
            const ageNine = agenineRef.current.value;
            const seekNine = seeknineRef.current.value;
            const preferNine = prefernineRef.current.value;
            const lifeNine = lifenineRef.current.value;
            const origineNine = origineRef.current.value;
            const contactNine = contactnineRef.current.value;
            const usernameNine = usernameRef.current.value;
            const passwordNine = passwordRef.current.value;
            const countryNine = countryRef.current.value;
            const locationNine = locationRef.current.value;
            const emailNine = emailRef.current.value;
            const telephoneNine = telephoneRef.current.value;
            const scoreNine = scorenineRef.current.value;

            Axios.post('https://rishtaapi.carions.net/create', {
                //withCredentials: true,
                sexeNine:sexeNine,
                sexeForNine:sexeForNine,
                ageNine:ageNine,
                seekNine:seekNine,
                preferNine:preferNine,
                lifeNine:lifeNine,
                origineNine:origineNine,
                contactNine:contactNine,
                usernameNine:usernameNine,
                passwordNine:passwordNine,
                countryNine:countryNine,
                locationNine:locationNine,
                emailNine:emailNine,
                telephoneNine:telephoneNine,
                scoreNine:scoreNine,
            }).then(() => {
                alert('Press Login button for notifications');
                landing.style.display = 'none';
                starter.style.display = 'block';
                starterone.style.display = 'block';
                starterphone.style.display = 'none';
                starterlogin.style.display = 'none';
                startertwo.style.display = 'none';
                starterthree.style.display = 'none';
                starterfour.style.display = 'none';
                starterfive.style.display = 'none';
                starterfiveman.style.display = 'none';
                startersix.style.display = 'none';
                starterseven.style.display = 'none';
                starterlist.style.display = 'none';
                startereight.style.display = 'none';
                starternine.style.display = 'none';
                terme.style.display = 'none';
                feedback.style.display = 'none';
            });
        }else{
            const pTexteMail = "Please enter your valid email address";
            AlertModal(elementOutId, elementDropId, pTexteMail, buttonHandler);
        }
    };

    return ( 
        <StarterContainer style={FontEcran} id="starternine">
            <FormScroller>
                <br/>
                <TextNormal>Your're almost done!</TextNormal>
                <br/>
                <div style={{display:'block',textAlign:'center'}}>
                    <div>
                        <LargeSelect id="origine" name="origine" ref={origineRef}>
                            <option value=''>Origin Country you're from</option>
                            <option value='india'>India</option>
                            <option value='pakistan'>Pakistan</option>
                            <option value='bengali'>Bengladesh</option>
                            <option value='afghan'>Afghanistan</option>
                            <option value='indonesia'>Indonesia</option>
                            <option value='iran'>Iran</option>
                            <option value='turkey'>Turkey</option>
                            <option value='malaysia'>Malaysia</option>
                        </LargeSelect>
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="text" name="username" id="username" ref={usernameRef} placeholder='Username' />
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="text" id="password" ref={passwordRef} placeholder="Password" />
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="text" name="country" ref={countryRef} id="country" placeholder="Country you currently live" />
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="text" name="location" ref={locationRef} id="location" placeholder="City you currently live" />
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="email" name="email" ref={emailRef} id="emailaddress" placeholder="Email" />
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="tel" name="telephone" ref={telephoneRef} maxLength="11" id="telephone" placeholder="Confirm your Phone number(11digits)" readOnly/>
                    </div>
                </div>
                <br/>
                <div style={{display:'none'}}>
                    <input type="text" id="sexenine" name="sexenine" ref={sexenineRef} placeholder="sexe"/>
                    <input type="text" id="sexefornine" name="sexefornine" ref={sexefornineRef} placeholder="sexefor"/>
                    <input type="text" id="agenine" name="agenine" ref={agenineRef} placeholder="age"/>
                    <input type="text" id="seeknine" name="seeknine" ref={seeknineRef} placeholder="seek"/>
                    <input type="text" id="prefernine" name="prefernine" ref={prefernineRef} placeholder="prefer"/>
                    <input type="text" id="lifenine" name="lifenine" ref={lifenineRef} placeholder="life"/>
                    <input type="text" id="contactnine" name="contactnine" ref={contactnineRef} placeholder="contact"/>
                    <input type="text" id="scorenine" name="scorenine" ref={scorenineRef} placeholder="score"/>
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarternine" onClick={addUserStarter}>Login Now</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartnine">Back to start</button>
                </BackStart>
                <div id="modaloutstarternine"></div>
                <div id="backdropoutstarternine"></div>

                <div id="modaloutalertstarternine"></div>
                <div id="backdropoutalertstarternine"></div>
            </FormScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterNine;