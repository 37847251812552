/* eslint-disable no-useless-escape */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

export const RishtaBase = () => {
    var dbName = '';
    var remoteCouch = '';
    var options = {
        live:true,
        retry:true,
        continuous:true,
        attachments:true,
    };

    dbName = new PouchDB('rishta');
    remoteCouch = new PouchDB('http://kouaskon:Mandidjouman1@127.0.0.1:5984/rishta');
    PouchDB.plugin(PouchFind);
    dbName.sync(remoteCouch,options);

    return dbName;
};

//alertmodal function
export const AlertModal = (elementOutId, elementDropId, pTexte, buttonHandler) =>{
    var divClearModal = document.getElementById(elementOutId);
    let divModal = document.createElement("div");
    divModal.innerHTML = `<div class="modalalert">
          <p>${pTexte}</p>
          <button class="btnalert btnalert--alt" id="${buttonHandler}">OK</button>
      </div>`;
    divClearModal.appendChild(divModal);

    var divClearBackdrop = document.getElementById(elementDropId);
    let divBack = document.createElement("div");
    divBack.innerHTML = `<div class="backdropalert"></div>`;
    divClearBackdrop.appendChild(divBack);

    var buttonChange = document.getElementById(buttonHandler);
    buttonChange.addEventListener("click", (e) => {
      e.preventDefault();
  
      var divClearModal = document.getElementById(elementOutId);
      while (divClearModal.hasChildNodes()) {
        divClearModal.removeChild(divClearModal.childNodes[0]);
      }
  
      var divClearModalDrop = document.getElementById(elementDropId);
      while (divClearModalDrop.hasChildNodes()) {
        divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
      }
    });
};

//validate email
export const ValidateEmail = (mail) =>{
    var resultat = '';
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
        resultat = 'valide';
        return resultat;
    }else{
        resultat = 'invalide';
        return resultat;
    }
}

export const getCurrentDate = () => {
  // Date object
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // Current Date
  var date = yyyy + "-" + mm + "-" + dd;
  return date;
}