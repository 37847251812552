import React from "react";

import { Layout } from "../layout/Layout";
import Accueil from "../accueil/Accueil";
import Landing from "../landing/Landing";
import Starter from '../starter/Starter';
import Dashboard from "../dashboard/Dashboard";
import Termes from "../footer/Termes";

import Footer from "../footer/Footer";
import Feedbacks from "../footer/Feedbacks";

function Home() {
    return ( 
        <Layout>
            <>
                <Accueil />
                <Landing />
                <Starter />
                <Dashboard />
                <Termes />
                <Feedbacks />
                <Footer />
            </>
        </Layout>
    );
}

export default Home;