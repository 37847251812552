//import Axios from 'axios';
//import {AlertModal} from '../helperfunctions/Helper';

window.addEventListener('load', () => {
    //termes hanlder
    document.getElementById('termes').addEventListener('click', termesChange);
    async function termesChange(e){
        e.preventDefault();

        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'none';
        document.getElementById('terme').style.display = 'block';
        document.getElementById('feedback').style.display = 'none';
    }

    //feedback handler
    document.getElementById('feedbacks').addEventListener('click', feedbacksChange);
    async function feedbacksChange(e){
        e.preventDefault();

        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'none';
        document.getElementById('terme').style.display = 'none';
        document.getElementById('feedback').style.display = 'block';
    }

    //submit feedback handler
    /*document.getElementById('submitfeedback').addEventListener('click', submitfeedbackChange);
    async function submitfeedbackChange(e){
        e.preventDefault();

        //alert
        const elementOutId = "modaloutalertfeedback";
        const elementDropId = "backdropoutalertfeedback";
        const buttonHandler = "ouiHandlerAlertFeedbackBtn";

        var ratingStarStr = document.getElementById('ratingstar').value;
        var textFeedbackStr = document.getElementById('textfeedback').value;
        console.log(ratingStarStr);
        console.log(textFeedbackStr);

        if(!ratingStarStr){
            const pTexteRating = "Select your rating";
            AlertModal(elementOutId, elementDropId, pTexteRating, buttonHandler);
        }else if(!textFeedbackStr){
            const pTexteArea = "Write something to emprove Rishta";
            AlertModal(elementOutId, elementDropId, pTexteArea, buttonHandler);
        }else{
            await Axios.post('https://rishtaapi.carions.net/api/feedback', {
                ratingStar:ratingStarStr,
                textAreaFeedback:textFeedbackStr,
            }).then(() => {
                const pTexteFeedback = "Thanks for your feedback";
                AlertModal(elementOutId, elementDropId, pTexteFeedback, buttonHandler);
                document.getElementById('landing').style.display = 'none';
                document.getElementById('starter').style.display = 'block';
                document.getElementById('starterone').style.display = 'block';
                document.getElementById('starterphone').style.display = 'none';
                document.getElementById('starterlogin').style.display = 'none';
                document.getElementById('startertwo').style.display = 'none';
                document.getElementById('starterthree').style.display = 'none';
                document.getElementById('starterfour').style.display = 'none';
                document.getElementById('starterfive').style.display = 'none';
                document.getElementById('starterfiveman').style.display = 'none';
                document.getElementById('startersix').style.display = 'none';
                document.getElementById('starterseven').style.display = 'none';
                document.getElementById('starterlist').style.display = 'none';
                document.getElementById('startereight').style.display = 'none';
                document.getElementById('starternine').style.display = 'none';
                document.getElementById('terme').style.display = 'none';
                document.getElementById('feedback').style.display = 'none';
            })
        }
    }*/

    //get back to start page
    document.getElementById('backstartfooter').addEventListener('click', backstartfooterChange);
    async function backstartfooterChange(e){
        e.preventDefault();

        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
        document.getElementById('terme').style.display = 'none';
        document.getElementById('feedback').style.display = 'none';
    }
})