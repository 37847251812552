import React from "react-dom";
import './footer.css';
import './pouchfooter';
import { FooterContainer, FooterBaseContainer, FooterLabel, FooterImage } from './FooterStyles';
import {MdOutlineFeedback} from 'react-icons/md';

function Footer() {
    return ( 
        <FooterContainer id="footer">
            <FooterBaseContainer>
                <FooterLabel>
                    <label style={{cursor:'pointer'}} id="termes">Terms</label>
                </FooterLabel>
                <FooterImage>
                    <MdOutlineFeedback id="feedbacks" />
                </FooterImage>
            </FooterBaseContainer>
            <p style={{color:'white'}}>Copyright 2022 &copy; <a style={{color:'white', textDecoration:'none', cursor:'pointer'}} href="https://www.carions.grandbico.com/">Carion's</a></p>
        </FooterContainer>
    );
}

export default Footer;