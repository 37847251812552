import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import { 
    StarterContainer,  
    ButtonStart,  
    FormScroller,
    TextNormal,
    TextLogin,  
    BackStart, 
    LargeInput,   
} from '../StarterStyles';

function StarterList() {
    return ( 
        <StarterContainer style={FontEcran} id="starterlist">
            <FormScroller>
                <br/>
                <TextNormal>Your're Real time matchmaker</TextNormal>
                <br/>
                <div style={{display:'block',textAlign:'center'}}>
                    <div>
                        <TextLogin>I am a</TextLogin>
                        <LargeInput type="text" id="gender" placeholder='gender' readOnly />
                    </div>
                    <br/>
                    <div>
                        <TextLogin>Looking for</TextLogin>
                        <LargeInput type="text" id="lookgender" placeholder='looking for' readOnly />
                    </div>
                    <br/>
                    <div>
                        <TextLogin>My age between</TextLogin>
                        <LargeInput type="text" id="agerange" placeholder='age between' readOnly />
                    </div>
                    <br/>
                    <div>
                        <TextLogin>Seek relationship</TextLogin>
                        <LargeInput type="text" id="seekrelation" placeholder='relationship' readOnly />
                    </div>
                    <br/>
                    <div>
                        <TextLogin>I Prefer member from</TextLogin>
                        <LargeInput type="text" id="originefrom" placeholder='origine from' readOnly />
                    </div>
                    <br/>
                    <div>
                        <TextLogin>My lifestyle</TextLogin>
                        <LargeInput type="text" id="lifestyle" placeholder='lifestyle' readOnly />
                    </div>
                    <br/>
                    <div>
                        <TextLogin>My prefered contact</TextLogin>
                        <LargeInput type="text" id="contactprefer" placeholder='contact' readOnly />
                    </div>
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterlist">Next</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartlist">Back to start</button>
                </BackStart>
            </FormScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterList;