import React from "react-dom";
import './dashboard.css';
import './pouchdashboard';
import rishta from './../ifti_3.jpg';

import { 
    DatingContainer,
    FormScrollerDating,
    TextDating,
    TermsLabelDating,
    //WidgetDatingTable,
    //WidgetDatingTh,
} from './DatingListStyles';

function DatingNotification() {
    return ( 
        <DatingContainer href="#datingnotification" style={FontEcran} id="datingnotification">
            <FormScrollerDating>
                <br/>
                <TextDating>Your Dating Notifications</TextDating>
                <br/>
                <TermsLabelDating>
                    Here will be display all Notifications about user requests from Rishta admin dashboard.
                    this page will be implemented after implementing all Rishta admin dashboard features.
                </TermsLabelDating>
            </FormScrollerDating>
        </DatingContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default DatingNotification;