/* eslint-disable no-redeclare */
//import Axios from 'axios';
import {AlertModal, /*ValidateEmail*/} from '../helperfunctions/Helper';

window.addEventListener('load', () => {

    //Starter One
    document.getElementById('getstarterone').addEventListener('click', getstarteroneChange);
    async function getstarteroneChange(e){
        e.preventDefault();

        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'none';
        document.getElementById('starterphone').style.display = 'block';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    };

    //stater Phone
    document.getElementById('getstarterphone').addEventListener('click', getstarteronephoneChange);
    async function getstarteronephoneChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertstarterphone";
        var elementDropId = "backdropoutalertstarterphone";
        var buttonHandler = "ouiHandlerAlertStarterPhoneBtn";

        //set fields values
        var phonenumberStr = document.getElementById('phonenumber').value;

        if(! phonenumberStr){
            var pTextechooseone = "Enter your UK phone number(11 digits)";
            AlertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            var phonenumber = /^\d{11}$/;
            if(!phonenumberStr.match(phonenumber)){
                var pTexteinvalidnumber = "Not a valid phone number";
                AlertModal(elementOutId,elementDropId,pTexteinvalidnumber,buttonHandler);
            }else{
                var thePhonenumberStr = document.getElementById('phonenumber').value;
                document.getElementById('telephone').value = thePhonenumberStr;

                document.getElementById('landing').style.display = 'none';
                document.getElementById('starter').style.display = 'block';
                document.getElementById('starterone').style.display = 'none';
                document.getElementById('starterphone').style.display = 'none';
                document.getElementById('starterlogin').style.display = 'none';
                document.getElementById('startertwo').style.display = 'block';
                document.getElementById('starterthree').style.display = 'none';
                document.getElementById('starterfour').style.display = 'none';
                document.getElementById('starterfive').style.display = 'none';
                document.getElementById('starterfiveman').style.display = 'none';
                document.getElementById('startersix').style.display = 'none';
                document.getElementById('starterseven').style.display = 'none';
                document.getElementById('starterlist').style.display = 'none';
                document.getElementById('startereight').style.display = 'none';
                document.getElementById('starternine').style.display = 'none';
            }
        }
    };

    document.getElementById('login').addEventListener('click', loginChange);
    async function loginChange(e){
        e.preventDefault();

        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'none';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'block';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    /*document.getElementById('getstarterlogin').addEventListener('click', getstarterloginChange);
    async function getstarterloginChange(e){
        e.preventDefault();

        //alert
        const elementOutId = "modaloutalertstarterlogin";
        const elementDropId = "backdropoutalertstarterlogin";
        const buttonHandler = "ouiHandlerAlertStarterLoginBtn";
        
        var loginPhoneNumberStr = document.getElementById('loginphonenumber').value;
        var loginPasswordStr = document.getElementById('loginpassword').value;

        var resultat1 = '';
        var userList1 = [];
        var resultat2 = '';
        var userList2 = [];
        var resultat3 = '';
        var userList3 = [];
        var userListReply = [];
        var userListProfile = [];

        if(!loginPhoneNumberStr){
            const pTextePhoneNumber = "Enter your Phone Number";
            AlertModal(elementOutId, elementDropId, pTextePhoneNumber, buttonHandler);
        }else if(!loginPasswordStr){
            const pTextePassword = "Enter your Password";
            AlertModal(elementOutId, elementDropId, pTextePassword, buttonHandler);
        }else{
            await Axios.post('https://rishtaapi.carions.net/api/login/get', {
                loginPhoneNumber:loginPhoneNumberStr,
                loginPassword:loginPasswordStr,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    Axios.post('https://rishtaapi.carions.net/api/login/get/reply', {
                        loginPhoneNumber:loginPhoneNumberStr,
                        loginPassword:loginPasswordStr,
                    }).then((response2) => {
                        resultat2 = response2.data.length;
                        if(resultat2 === 0){
                            Axios.post('https://rishtaapi.carions.net/api/login/get/profile', {
                                loginPhoneNumber:loginPhoneNumberStr,
                                loginPassword:loginPasswordStr,
                            }).then((response3) => {
                                resultat3 = response3.data.length;
                                if(resultat3 === 0){
                                    const pTexteInfo = "Thanks. You have completed the step and will be contacted by phone or notification to the next step.";
                                    AlertModal(elementOutId, elementDropId, pTexteInfo, buttonHandler);
                                }else{
                                    userList3 = response3.data; 
                                    var resultatprofile = document.getElementById('resultatprofile');
                                    // Remove all child nodes from the div resultatreply
                                    while (resultatprofile.hasChildNodes()) {
                                        resultatprofile.removeChild(resultatprofile.childNodes[0]);
                                    }

                                    let mapUserList3 = userList3.map((val, j) => {
                                        return `
                                            <div key=${j} className="resultatlist">
                                                <button id='idProfileHandler' className="resultlistbutton">Clik profile ${val.username_starter}</button>
                                            </div>
                                        `
                                    });

                                    resultatprofile.innerHTML = mapUserList3;
                                    document.getElementById('resultatreply').style.display = 'none';
                                    document.getElementById('resultatprofile').style.display = 'block';

                                    //idProfileHandler function
                                    var profile = document.getElementById('profile');
                                    document.getElementById('idProfileHandler').addEventListener('click', idProfileHandlerChange);
                                    async function idProfileHandlerChange(e){
                                        e.preventDefault();

                                        await Axios.post('https://rishtaapi.carions.net/api/profile', {
                                            loginPhoneNumber:loginPhoneNumberStr,
                                            loginPassword:loginPasswordStr,
                                        }).then((response5) => {
                                           userListProfile = response5.data;
                                           // Remove all child nodes from the div resultatreply
                                            while (profile.hasChildNodes()) {
                                                profile.removeChild(profile.childNodes[0]);
                                            }

                                            let mapUserList5 = userListProfile.map((valprofile, m) => {
                                                return `
                                                   <div className="resultatlist" id="profile" key=${m}>
                                                        <div>
                                                            <div style={{display:'block'}}>
                                                                <div>
                                                                    <LargeInput type="text" readOnly value=${valprofile.id_profile} id="idProfile" ref={idProfileRef} />
                                                                </div>
                                                            </dv>
                                                            <div>
                                                                <h1>${valprofile.content_profile}</h1>
                                                            </div>
                                                            <br/>
                                                            <label style={{display:'block',textAlign:'center'}}>About You</label>
                                                            <div style={{display:'block',textAlign:'center'}}>
                                                                <TextArea id="textAreaProfileYou" ref={textAreaProfileYouRef}></TextArea>
                                                            </div> 
                                                            <br/>
                                                            <label style={{display:'block',textAlign:'center'}}>Describe Your futur soulemate</label>
                                                            <div style={{display:'block',textAlign:'center'}}>
                                                                <TextArea id="textAreaProfileSoulemate" ref={textAreaProfileSoulemateRef}></TextArea>
                                                            </div>
                                                            <br/>
                                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                                <ButtonStart id="confirmProfileHandler">Send Profile</ButtonStart>
                                                            </div>
                                                            <br/><br/>
                                                        </div>
                                                    </div>
                                                `
                                            })
                                            profile.innerHTML = mapUserList5;
                                            document.getElementById('profile').style.display = 'block';

                                            //COMPLETE FROM HERE CONFIRM PROFILE HANDLER
                                            document.getElementById('confirmProfileHandler').addEventListener('click', confirmProfileHandlerChange);
                                            async function confirmProfileHandlerChange(e){
                                                e.preventDefault();

                                                var idProfileStr = document.getElementById('idProfile').value;
                                                var textAreaProfileYouStr = document.getElementById('textAreaProfileYou').value;
                                                var textAreaProfileSoulemateStr = document.getElementById('textAreaProfileSoulemate').value;

                                                if(!textAreaProfileYouStr){
                                                    const pTexteYou = "Write Something About You";
                                                    AlertModal(elementOutId, elementDropId, pTexteYou, buttonHandler);
                                                }else if(!textAreaProfileSoulemateStr){
                                                    const pTexteSoulmate = "Write something about Your futur soulemate";
                                                    AlertModal(elementOutId, elementDropId, pTexteSoulmate, buttonHandler);
                                                }else{
                                                    await Axios.post("https://rishtaapi.carions.net/api/update/profile", {
                                                        idProfile:idProfileStr,
                                                        textAreaProfileYou:textAreaProfileYouStr,
                                                        textAreaProfileSoulemate:textAreaProfileSoulemateStr,
                                                    }).then(() => {
                                                        const pTexteAppointment = "Thanks. You'll be contacted ASAP to arrange an appointment";
                                                        AlertModal(elementOutId, elementDropId, pTexteAppointment, buttonHandler);
                                                        document.getElementById('landing').style.display = 'none';
                                                        document.getElementById('starter').style.display = 'block';
                                                        document.getElementById('starterone').style.display = 'block';
                                                        document.getElementById('starterphone').style.display = 'none';
                                                        document.getElementById('starterlogin').style.display = 'none';
                                                        document.getElementById('startertwo').style.display = 'none';
                                                        document.getElementById('starterthree').style.display = 'none';
                                                        document.getElementById('starterfour').style.display = 'none';
                                                        document.getElementById('starterfive').style.display = 'none';
                                                        document.getElementById('starterfiveman').style.display = 'none';
                                                        document.getElementById('startersix').style.display = 'none';
                                                        document.getElementById('starterseven').style.display = 'none';
                                                        document.getElementById('starterlist').style.display = 'none';
                                                        document.getElementById('startereight').style.display = 'none';
                                                        document.getElementById('starternine').style.display = 'none';
                                                        document.getElementById('terme').style.display = 'none';
                                                        document.getElementById('feedback').style.display = 'none';
                                                    })
                                                }
                                            }
                                        })
                                    }//
                                }
                            })
                        }else{
                            userList2 = response2.data;
                            var resultatreply = document.getElementById('resultatreply');
                            // Remove all child nodes from the div resultatreply
                            while (resultatreply.hasChildNodes()) {
                                resultatreply.removeChild(resultatreply.childNodes[0]);
                            }

                            let mapUserList2 = userList2.map((val, i) => {
                                return `
                                    <div key=${i} className="resultatlist">
                                        <button id='idReplyHandler' className="resultlistbutton">Click to reply ${val.username_starter}</button>
                                    </div>
                                `
                            });
        
                            resultatreply.innerHTML = mapUserList2;
                            document.getElementById('resultatreply').style.display = 'block';
                            document.getElementById('resultatprofile').style.display = 'none';

                            //idReplyHandler function
                            var reply = document.getElementById('reply');
                            document.getElementById('idReplyHandler').addEventListener('click', idReplyHandlerChange);
                            async function idReplyHandlerChange(e){
                                e.preventDefault();

                                await Axios.post('https://rishtaapi.carions.net/api/reply', {
                                    loginPhoneNumber:loginPhoneNumberStr,
                                    loginPassword:loginPasswordStr,
                                }).then((response4) => {
                                    userListReply = response4.data;
                                    // Remove all child nodes from the div resultatreply
                                    while (reply.hasChildNodes()) {
                                        reply.removeChild(reply.childNodes[0]);
                                    }

                                    let mapUserList4 = userListReply.map((valreply, k) => {
                                        return `
                                            <div className="resultatlist" id="reply" key=${k}>
                                                <div>
                                                    <div style={{display:'block'}}>
                                                        <div>
                                                            <LargeInput type="text" readOnly value=${valreply.id_reply} id="idReply" ref={idReplyRef} />
                                                        </div>
                                                        <div>
                                                            <LargeInput type="text" readOnly value=${valreply.id_starter} id="idStarter" ref={idStarterRef} />
                                                        </div>
                                                    </dv>
                                                    <div>
                                                        <h1>${valreply.content_reply}</h1>
                                                    </div>
                                                    <br/>
                                                    <label style={{display:'block',textAlign:'center'}}>Phone Number</label>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type="text" readOnly value=${valreply.phone_starter} id="phoneReply" ref={phoneReplyRef} />
                                                    </div>
                                                    <br/>
                                                    <label style={{display:'block',textAlign:'center'}}>Chat Channel</label>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <LargeInput type="text" readOnly value=${valreply.contact_starter} id="contactReply" ref={contactReplyRef} />
                                                    </div>
                                                    <br/>
                                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                                        <ButtonStart id="confirmReplyHandler">Confirm</ButtonStart>
                                                    </div>
                                                    <br/><br/>
                                                </div>
                                            </div>
                                        `
                                    })
                                    reply.innerHTML = mapUserList4;
                                    document.getElementById('reply').style.display = 'block';

                                    //COMPLETE FROM HERE CONFIRM REPLY HANDLER
                                    document.getElementById('confirmReplyHandler').addEventListener('click', confirmReplyHandlerChange);
                                    async function confirmReplyHandlerChange(e){
                                        e.preventDefault();

                                        var idReplyStr = document.getElementById('idReply').value;
                                        var idStarterStr = document.getElementById('idStarter').value;

                                        await Axios.post("https://rishtaapi.carions.net/api/confirm/reply", {
                                            idReply:idReplyStr,
                                            idStarter:idStarterStr,
                                        }).then(() => {
                                            const pTexteConfirmed = "You've successfully confirmed your contacts";
                                            AlertModal(elementOutId, elementDropId, pTexteConfirmed, buttonHandler);
                                            document.getElementById('landing').style.display = 'none';
                                            document.getElementById('starter').style.display = 'block';
                                            document.getElementById('starterone').style.display = 'block';
                                            document.getElementById('starterphone').style.display = 'none';
                                            document.getElementById('starterlogin').style.display = 'none';
                                            document.getElementById('startertwo').style.display = 'none';
                                            document.getElementById('starterthree').style.display = 'none';
                                            document.getElementById('starterfour').style.display = 'none';
                                            document.getElementById('starterfive').style.display = 'none';
                                            document.getElementById('starterfiveman').style.display = 'none';
                                            document.getElementById('startersix').style.display = 'none';
                                            document.getElementById('starterseven').style.display = 'none';
                                            document.getElementById('starterlist').style.display = 'none';
                                            document.getElementById('startereight').style.display = 'none';
                                            document.getElementById('starternine').style.display = 'none';
                                            document.getElementById('terme').style.display = 'none';
                                            document.getElementById('feedback').style.display = 'none';
                                        })
                                    }//**
                                })
                            }//
                        }
                    })
                }else{
                    userList1 = response1.data;
                    var resultatreply = document.getElementById('resultatreply');
                    // Remove all child nodes from the div resultatreply
                    while (resultatreply.hasChildNodes()) {
                        resultatreply.removeChild(resultatreply.childNodes[0]);
                    }

                    let mapUserList1 = userList1.map((val, i) => {
                        return `
                            <div key=${i} className="resultatlist">
                                <button id='idReplyHandler' className="resultlistbutton">Click to reply ${val.username_starter}</button>
                            </div>
                        `
                    });

                    resultatreply.innerHTML = mapUserList1;
                    document.getElementById('resultatreply').style.display = 'block';
                    document.getElementById('resultatprofile').style.display = 'none';

                    //idReplyHandler function
                    var reply = document.getElementById('reply');
                    document.getElementById('idReplyHandler').addEventListener('click', idReplyHandlerChange);
                    async function idReplyHandlerChange(e){
                        e.preventDefault();

                        await Axios.post('https://rishtaapi.carions.net/api/reply', {
                            loginPhoneNumber:loginPhoneNumberStr,
                            loginPassword:loginPasswordStr,
                        }).then((response5) => {
                            userListReply = response5.data;
                            // Remove all child nodes from the div resultatreply
                            while (reply.hasChildNodes()) {
                                reply.removeChild(reply.childNodes[0]);
                            }

                            let mapUserList5 = userListReply.map((valreply, l) => {
                                return `
                                    <div className="resultatlist" id="reply" key=${l}>
                                        <div>
                                            <div style={{display:'block'}}>
                                                <div>
                                                    <LargeInput type="text" readOnly value=${valreply.id_reply} id="idReply" ref={idReplyRef} />
                                                </div>
                                                <div>
                                                    <LargeInput type="text" readOnly value=${valreply.id_starter} id="idStarter" ref={idStarterRef} />
                                                </div>
                                            </dv>
                                            <div>
                                                <h1>${valreply.content_reply}</h1>
                                            </div>
                                            <br/>
                                            <label style={{display:'block',textAlign:'center'}}>Phone Number</label>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <LargeInput type="text" readOnly value=${valreply.phone_starter} id="phoneReply" ref={phoneReplyRef} />
                                            </div>
                                            <br/>
                                            <label style={{display:'block',textAlign:'center'}}>Chat Channel</label>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <LargeInput type="text" readOnly value=${valreply.contact_starter} id="contactReply" ref={contactReplyRef} />
                                            </div>
                                            <br/>
                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                <ButtonStart id="confirmReplyHandler">Confirm</ButtonStart>
                                            </div>
                                            <br/><br/>
                                        </div>
                                    </div>
                                `
                            })
                            reply.innerHTML = mapUserList5;
                            document.getElementById('reply').style.display = 'block';

                            //COMPLETE FROM HERE CONFIRM REPLY HANDLER
                            document.getElementById('confirmReplyHandler').addEventListener('click', confirmReplyHandlerChange);
                            async function confirmReplyHandlerChange(e){
                                e.preventDefault();

                                var idReplyStr = document.getElementById('idReply').value;
                                var idStarterStr = document.getElementById('idStarter').value;

                                await Axios.post("https://rishtaapi.carions.net/api/confirm/reply", {
                                    idReply:idReplyStr,
                                    idStarter:idStarterStr,
                                }).then(() => {
                                    const pTexteConfirmed = "You've successfully confirmed your contacts";
                                    AlertModal(elementOutId, elementDropId, pTexteConfirmed, buttonHandler);
                                    document.getElementById('landing').style.display = 'none';
                                    document.getElementById('starter').style.display = 'block';
                                    document.getElementById('starterone').style.display = 'block';
                                    document.getElementById('starterphone').style.display = 'none';
                                    document.getElementById('starterlogin').style.display = 'none';
                                    document.getElementById('startertwo').style.display = 'none';
                                    document.getElementById('starterthree').style.display = 'none';
                                    document.getElementById('starterfour').style.display = 'none';
                                    document.getElementById('starterfive').style.display = 'none';
                                    document.getElementById('starterfiveman').style.display = 'none';
                                    document.getElementById('startersix').style.display = 'none';
                                    document.getElementById('starterseven').style.display = 'none';
                                    document.getElementById('starterlist').style.display = 'none';
                                    document.getElementById('startereight').style.display = 'none';
                                    document.getElementById('starternine').style.display = 'none';
                                    document.getElementById('terme').style.display = 'none';
                                    document.getElementById('feedback').style.display = 'none';
                                })
                            }//**
                        })
                    }//
                }
            });
        }
    }*/

    document.getElementById('getstartertwo').addEventListener('click', getstartertwoChange);
    async function getstartertwoChange(e){
        e.preventDefault();

        var sexeStr = document.getElementById('sexe').innerText;
        var sexeforStr = document.getElementById('sexefor').innerText;
        
        //alert
        var elementOutId = "modaloutalertstartertwo";
        var elementDropId = "backdropoutalertstartertwo";
        var buttonHandler = "ouiHandlerAlertStarterTwoBtn";

        if(sexeStr === '' || sexeforStr === ''){
            var pTextechooseone = "Choose I am and Looking for";
            AlertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'block';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('starterlist').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstarterthree').addEventListener('click', getstarterthreeChange);
    async function getstarterthreeChange(e){
        e.preventDefault();

        var ageStr = document.getElementById('age').innerText;
        
        //alert
        var elementOutId = "modaloutalertstarterthree";
        var elementDropId = "backdropoutalertstarterthree";
        var buttonHandler = "ouiHandlerAlertStarterThreeBtn";

        if(ageStr === ''){
            var pTextechooseone = "Choose your age range";
            AlertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'block';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('starterlist').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstarterfour').addEventListener('click', getstarterfourChange);
    async function getstarterfourChange(e){
        e.preventDefault();

        var seekStr = document.getElementById('seek').innerText;
        var sexeforStr = document.getElementById('sexefor').innerText;
        
        //alert
        var elementOutId = "modaloutalertstarterfour";
        var elementDropId = "backdropoutalertstarterfour";
        var buttonHandler = "ouiHandlerAlertStarterFourBtn";

        if(seekStr === ''){
            var pTextechooseone = "Choose what you are seeking";
            AlertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            if(sexeforStr === 'lookwoman'){
                document.getElementById('starterfive').style.display = 'block';
                document.getElementById('starterfiveman').style.display = 'none';
            }else{
                document.getElementById('starterfive').style.display = 'none';
                document.getElementById('starterfiveman').style.display = 'block';
            }
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('starterlist').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstarterfive').addEventListener('click', getstarterfiveChange);
    async function getstarterfiveChange(e){
        e.preventDefault();

        var preferStr = document.getElementById('prefer').innerText;
        
        //alert
        var elementOutId = "modaloutalertstarterfive";
        var elementDropId = "backdropoutalertstarterfive";
        var buttonHandler = "ouiHandlerAlertStarterFiveBtn";

        if(preferStr === ''){
            var pTextechooseone = "Choose your group member";
            AlertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'block';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('starterlist').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstarterfiveman').addEventListener('click', getstarterfivemanChange);
    async function getstarterfivemanChange(e){
        e.preventDefault();

        var preferStr = document.getElementById('prefer').innerText;
        
        //alert
        var elementOutId = "modaloutalertstarterfiveman";
        var elementDropId = "backdropoutalertstarterfiveman";
        var buttonHandler = "ouiHandlerAlertStarterFiveManBtn";

        if(preferStr === ''){
            var pTextechooseone = "Choose your group member";
            AlertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'block';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('starterlist').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstartersix').addEventListener('click', getstartersixChange);
    async function getstartersixChange(e){
        e.preventDefault();

        var lifeStr = document.getElementById('life').innerText;
        //change value of sexe and sexefor to query mysql in index.js server to trigger count.
        var sexeStr = document.getElementById('sexe').innerText;
        var sexeforStr = document.getElementById('sexefor').innerText;
        var sexeSearchStr = '';
        var sexeForSearchStr = '';
        
        //alert
        var elementOutId = "modaloutalertstartersix";
        var elementDropId = "backdropoutalertstartersix";
        var buttonHandler = "ouiHandlerAlertStarterSixBtn";

        if(lifeStr === ''){
            var pTextechooseone = "Choose your lifestyle";
            AlertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            //change value to use such as data in server
            if((sexeStr === 'woman') && (sexeforStr === 'lookman')){
                sexeSearchStr = 'man';
                document.getElementById('sexeseven').value = sexeSearchStr;
                sexeForSearchStr = 'lookwoman';
                document.getElementById('sexeforseven').value = sexeForSearchStr;

            }else if((sexeStr === 'woman') && (sexeforStr === 'lookwoman')){
                sexeSearchStr = 'woman';
                document.getElementById('sexeseven').value = sexeSearchStr;
                sexeForSearchStr = 'lookwoman';
                document.getElementById('sexeforseven').value = sexeForSearchStr;
            }else if((sexeStr === 'man') && (sexeforStr === 'lookwoman')){
                sexeSearchStr = 'woman';
                document.getElementById('sexeseven').value = sexeSearchStr;
                sexeForSearchStr = 'lookman';
                document.getElementById('sexeforseven').value = sexeForSearchStr;
            }else if((sexeStr === 'man') && (sexeforStr === 'lookman')){
                sexeSearchStr = 'man';
                document.getElementById('sexeseven').value = sexeSearchStr;
                sexeForSearchStr = 'lookman';
                document.getElementById('sexeforseven').value = sexeForSearchStr;
            }
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'block';
            document.getElementById('starterlist').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstarterseven').addEventListener('click', getstartersevenChange);
    async function getstartersevenChange(e){
        e.preventDefault();

        var contactStr = document.getElementById('contact').innerText;
        
        //alert
        var elementOutId = "modaloutalertstarterseven";
        var elementDropId = "backdropoutalertstarterseven";
        var buttonHandler = "ouiHandlerAlertStarterSevenBtn";

        if(contactStr === ''){
            var pTextechooseone = "Choose your prefered contact";
            AlertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            //retrieve data from accueil.js
            var sexeStr = document.getElementById('sexe').innerText;
            var sexeforStr = document.getElementById('sexefor').innerText;
            var ageStr = document.getElementById('age').innerText;
            var seekStr = document.getElementById('seek').innerText;
            var preferStr = document.getElementById('prefer').innerText;
            var lifeStr = document.getElementById('life').innerText;
            var contactStr = document.getElementById('contact').innerText;
            //populate getstarterlist
            document.getElementById('gender').value = sexeStr;

            if(sexeforStr === 'lookman'){
                document.getElementById('lookgender').value = 'man';
            }else if(sexeforStr === 'lookwoman'){
                document.getElementById('lookgender').value = 'woman';
            }

            if(ageStr === 'age24'){
                document.getElementById('agerange').value = '>24';
            }else if(ageStr === 'age25'){
                document.getElementById('agerange').value = '25 - 34'; 
            }else if(ageStr === 'age35'){
                document.getElementById('agerange').value = '35 - 44'; 
            }else if(ageStr === 'age45'){
                document.getElementById('agerange').value = '45 - 54';
            }else if(ageStr === 'age55'){
                document.getElementById('agerange').value = '55 - 64'; 
            }else if(ageStr === 'age65'){
                document.getElementById('agerange').value = '>64';
            }

            if(seekStr === 'serious'){
                document.getElementById('seekrelation').value = 'Serious relationship';
            }else if(seekStr === 'life'){
                document.getElementById('seekrelation').value = 'Life partner';
            }else if(seekStr === 'companion'){
                document.getElementById('seekrelation').value = 'Companion'; 
            }else if(seekStr === 'travel'){
                document.getElementById('seekrelation').value = 'Travel mate'; 
            }else if(seekStr === 'online'){
                document.getElementById('seekrelation').value = 'Online friendship'; 
            }else if(seekStr === 'flirting"'){
                document.getElementById('seekrelation').value = 'Flirting/talks';
            }

            document.getElementById('originefrom').value = preferStr;

            document.getElementById('lifestyle').value = lifeStr;

            if(contactStr === 'texte'){
                document.getElementById('contactprefer').value = 'Chat/letters';
            }else{
                document.getElementById('contactprefer').value = contactStr;
            }

            /*try {
                const sexeSeven = document.getElementById('sexeseven').value;
                const sexeForSeven = document.getElementById('sexeforseven').value;
                const seekSeven = document.getElementById('seekseven').value;
                const preferSeven = document.getElementById('preferseven').value;

                await Axios.post('https://rishtaapi.carions.net/api/allorigin/get', {
                    sexeSeven:sexeSeven,
                    sexeForSeven:sexeForSeven,
                    preferSeven:preferSeven,
                }).then((response1) => {
                    document.getElementById('resultatallseven').value = response1.data.length;
                })

                await Axios.post('https://rishtaapi.carions.net/api/originseek/get', {
                    sexeSeven:sexeSeven,
                    sexeForSeven:sexeForSeven,
                    seekSeven:seekSeven,
                    preferSeven:preferSeven,
                }).then((response2) => {
                    //console.log(response2.data.length)
                    document.getElementById('resultatoneseven').value = response2.data.length;

                });

                document.getElementById('landing').style.display = 'none';
                document.getElementById('starter').style.display = 'block';
                document.getElementById('starterone').style.display = 'none';
                document.getElementById('starterphone').style.display = 'none';
                document.getElementById('starterlogin').style.display = 'none';
                document.getElementById('startertwo').style.display = 'none';
                document.getElementById('starterthree').style.display = 'none';
                document.getElementById('starterfour').style.display = 'none';
                document.getElementById('starterfive').style.display = 'none';
                document.getElementById('starterfiveman').style.display = 'none';
                document.getElementById('startersix').style.display = 'none';
                document.getElementById('starterseven').style.display = 'none';
                document.getElementById('starterlist').style.display = 'block';
                document.getElementById('startereight').style.display = 'none';
                document.getElementById('starternine').style.display = 'none';
            } catch (err) {
                var pTexteError = "An error has occured";
                AlertModal(elementOutId, elementDropId, pTexteError, buttonHandler);
            }*/
        }
    }

    document.getElementById('getstarterlist').addEventListener('click', getstarterlistChange);
    async function getstarterlistChange(e){
        e.preventDefault();

        var resultatallsevenStr = parseFloat(document.getElementById('resultatallseven').value);
        var resultatonesevenStr = parseFloat(document.getElementById('resultatoneseven').value);
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

        if(resultatallsevenStr === 0){
            document.getElementById('datingresult').innerText = '0%';
            document.getElementById('scorenine').value = '0%';

            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('starterlist').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'block';
        }else if(resultatonesevenStr === 0){
            document.getElementById('datingresult').innerText = '0%';
            document.getElementById('scorenine').value = '0%';

            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('starterlist').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'block';
        }else if((resultatallsevenStr >= 0) && (resultatonesevenStr >= 0)){
            //calculate rating score in percentage
            var resultmatch = ((resultatonesevenStr / resultatallsevenStr) * 100);
            var roundnumber = Math.ceil(resultmatch);
            document.getElementById('datingresult').innerText = roundnumber.toString()+'%';
            document.getElementById('scorenine').value = roundnumber.toString()+'%';
            
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('starterlist').style.display = 'none';
            document.getElementById('startereight').style.display = 'block';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstartereight').addEventListener('click', getstartereightChange);
    async function getstartereightChange(e){
        e.preventDefault();

        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'none';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'block';
    }

    //Login now
    /*document.getElementById('getstarternine').addEventListener('click', getstarternineChange);
    async function getstarternineChange(e){
        e.preventDefault();

        //alert
        const elementOutId = "modaloutalertstarternine";
        const elementDropId = "backdropoutalertstarternine";
        const buttonHandler = "ouiHandlerAlertStarterNineBtn";

        //set fields values
        var sexeStr = document.getElementById('sexe').innerText;
        var sexeforStr = document.getElementById('sexefor').innerText;
        var ageStr = document.getElementById('age').innerText;
        var seekStr = document.getElementById('seek').innerText;
        var preferStr = document.getElementById('prefer').innerText;
        var lifeStr = document.getElementById('life').innerText;
        var contactStr = document.getElementById('contact').innerText;
        var origineStr = document.getElementById('origine').value;
        var usernamelogStr = document.getElementById('username').value;
        var passwordlogStr = document.getElementById('password').value;
        var countryStr = document.getElementById('country').value;
        var locationStr = document.getElementById('location').value;
        var emailStr = document.getElementById('emailaddress').value;
        var telephoneStr = document.getElementById('telephone').value;
        var datingresultStr = document.getElementById('datingresult').innerText;
        //var prefixeStr = '0044';
        //var dateStr = getCurrentDate();
        //var documentStr = 'starter';

        if(!sexeStr){
            var pTextesexe = "Choose I am";
            AlertModal(elementOutId,elementDropId,pTextesexe,buttonHandler);
        }else if(!sexeforStr){
            var pTextesexefor = "Choose Looking for";
            AlertModal(elementOutId,elementDropId,pTextesexefor,buttonHandler);
        }else if(!ageStr){
            var pTexteage = "Choose your age range";
            AlertModal(elementOutId,elementDropId,pTexteage,buttonHandler);
        }else if(!seekStr){
            var pTexteseek = "Choose what you are seeking";
            AlertModal(elementOutId,elementDropId,pTexteseek,buttonHandler);
        }else if(!preferStr){
            var pTexteprefer = "Choose your group member";
            AlertModal(elementOutId,elementDropId,pTexteprefer,buttonHandler);
        }else if(!lifeStr){
            var pTextelife = "Choose your lifestyle";
            AlertModal(elementOutId,elementDropId,pTextelife,buttonHandler);
        }else if(!contactStr){
            var pTextecontact = "Choose your prefred contact";
            AlertModal(elementOutId,elementDropId,pTextecontact,buttonHandler);
        }else if(!origineStr){
            var pTexteorigine = "Enter the country you're from";
            AlertModal(elementOutId,elementDropId,pTexteorigine,buttonHandler);
        }else if(!usernamelogStr){
            var pTexteusername = "Enter your username";
            AlertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
        }else if(!passwordlogStr){
            var pTextepassword = "Enter your password";
            AlertModal(elementOutId,elementDropId,pTextepassword,buttonHandler);
        }else if(!countryStr){
            var pTextecountry = "Enter the country you currently live";
            AlertModal(elementOutId,elementDropId,pTextecountry,buttonHandler);
        }else if(!locationStr){
            var pTextelocation = "Enter the city you currently live";
            AlertModal(elementOutId,elementDropId,pTextelocation,buttonHandler);
        }else if(!emailStr){
            var pTextemail = "Enter your valid email";
            AlertModal(elementOutId,elementDropId,pTextemail,buttonHandler);
        }else{
            var valideremail = ValidateEmail(emailStr);
            if(valideremail === 'invalide'){
                var pTexteinvalidemail = "You have entered an invalid email address!";
                AlertModal(elementOutId,elementDropId,pTexteinvalidemail,buttonHandler);
            }else if(valideremail === 'valide'){
                if(!telephoneStr){
                    var pTextetelephone = "Confirm your valid phone number";
                    AlertModal(elementOutId,elementDropId,pTextetelephone,buttonHandler);
                }else{
                    await Axios.post('https://rishtaapi.carions.net/create', {
                        sexeNine:sexeStr,
                        sexeForNine:sexeforStr,
                        ageNine:ageStr,
                        seekNine:seekStr,
                        preferNine:preferStr,
                        lifeNine:lifeStr,
                        origineNine:origineStr,
                        contactNine:contactStr,
                        usernameNine:usernamelogStr,
                        passwordNine:passwordlogStr,
                        countryNine:countryStr,
                        locationNine:locationStr,
                        emailNine:emailStr,
                        telephoneNine:telephoneStr,
                        scoreNine:datingresultStr,
                    }).then(() => {
                        const pTexteMail = "Press Login button for notifications";
                        AlertModal(elementOutId, elementDropId, pTexteMail, buttonHandler);
                        document.getElementById('landing').style.display = 'none';
                        document.getElementById('starter').style.display = 'block';
                        document.getElementById('starterone').style.display = 'block';
                        document.getElementById('starterphone').style.display = 'none';
                        document.getElementById('starterlogin').style.display = 'none';
                        document.getElementById('startertwo').style.display = 'none';
                        document.getElementById('starterthree').style.display = 'none';
                        document.getElementById('starterfour').style.display = 'none';
                        document.getElementById('starterfive').style.display = 'none';
                        document.getElementById('starterfiveman').style.display = 'none';
                        document.getElementById('startersix').style.display = 'none';
                        document.getElementById('starterseven').style.display = 'none';
                        document.getElementById('starterlist').style.display = 'none';
                        document.getElementById('startereight').style.display = 'none';
                        document.getElementById('starternine').style.display = 'none';
                        document.getElementById('terme').style.display = 'none';
                        document.getElementById('feedback').style.display = 'none';
                    })
                }
            }else{
                const pTexteMail = "Please enter your valid email address";
                AlertModal(elementOutId, elementDropId, pTexteMail, buttonHandler);
            }
        }
    }*/

    //coding all checkboxes
    //startertwo
    //1.i am a woman
    document.getElementById('iamwoman').addEventListener('change', iamwomanChange);
    async function iamwomanChange(e){
        e.preventDefault();

        var iamwomanStr = document.getElementById('iamwoman').value;
        var sexeStr = document.getElementById('sexe');
        sexeStr.innerText = iamwomanStr;
        document.getElementById('iamman').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('sexenine').value = iamwomanStr;
    }

    //2.i am a man
    document.getElementById('iamman').addEventListener('change', iammanChange);
    async function iammanChange(e){
        e.preventDefault();

        var iammanStr = document.getElementById('iamman').value;
        var sexeStr = document.getElementById('sexe');
        sexeStr.innerText = iammanStr;
        document.getElementById('iamwoman').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('sexenine').value = iammanStr;
    }

    //3.looking for a woman
    document.getElementById('lookingwoman').addEventListener('change', lookingwomanChange);
    async function lookingwomanChange(e){
        e.preventDefault();

        var lookingwomanStr = document.getElementById('lookingwoman').value;
        var sexeforStr = document.getElementById('sexefor');
        sexeforStr.innerText = lookingwomanStr;
        document.getElementById('lookingmman').checked = false ; //activate if only one choice acceptable
        //assign values to input in getstarter nine
        document.getElementById('sexefornine').value = lookingwomanStr;
    }

    //4.looking for a man
    document.getElementById('lookingmman').addEventListener('change', lookingmmanChange);
    async function lookingmmanChange(e){
        e.preventDefault();

        var lookingmmanStr = document.getElementById('lookingmman').value;
        var sexeforStr = document.getElementById('sexefor');
        sexeforStr.innerText = lookingmmanStr;
        document.getElementById('lookingwoman').checked = false ; //activate if only one choice acceptable
        //assign values to input in getstarter nine
        document.getElementById('sexefornine').value = lookingmmanStr;
    }

    //starterthree
    //1.myage24
    document.getElementById('myage24').addEventListener('change', myage24Change);
    async function myage24Change(e){
        e.preventDefault();

        var myage24Str = document.getElementById('myage24').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage24Str;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('agenine').value = myage24Str;
    }

    //1.myage25
    document.getElementById('myage25').addEventListener('change', myage25Change);
    async function myage25Change(e){
        e.preventDefault();

        var myage25Str = document.getElementById('myage25').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage25Str;
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('agenine').value = myage25Str;
    }

    //1.myage35
    document.getElementById('myage35').addEventListener('change', myage35Change);
    async function myage35Change(e){
        e.preventDefault();

        var myage35Str = document.getElementById('myage35').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage35Str;
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('agenine').value = myage35Str;
    }

    //1.myage45
    document.getElementById('myage45').addEventListener('change', myage45Change);
    async function myage45Change(e){
        e.preventDefault();

        var myage45Str = document.getElementById('myage45').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage45Str;
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('agenine').value = myage45Str;
    }

    //1.myage55
    document.getElementById('myage55').addEventListener('change', myage55Change);
    async function myage55Change(e){
        e.preventDefault();

        var myage55Str = document.getElementById('myage55').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage55Str;
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage65').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('agenine').value = myage55Str;
    }

    //1.myage65
    document.getElementById('myage65').addEventListener('change', myage65Change);
    async function myage65Change(e){
        e.preventDefault();

        var myage65Str = document.getElementById('myage65').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage65Str;
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('agenine').value = myage65Str;
    }

    //starterfour
    //1.seekserious
    document.getElementById('seekserious').addEventListener('change', seekseriousChange);
    async function seekseriousChange(e){
        e.preventDefault();

        var seekseriousStr = document.getElementById('seekserious').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seekseriousStr;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('seeknine').value = seekseriousStr;
        //assign values to input in getstarter seven
        document.getElementById('seekseven').value = seekseriousStr;
    }

    //2.seeklife
    document.getElementById('seeklife').addEventListener('change', seeklifeChange);
    async function seeklifeChange(e){
        e.preventDefault();

        var seeklifeStr = document.getElementById('seeklife').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seeklifeStr;
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('lifenine').value = seeklifeStr;
        //assign values to input in getstarter seven
        document.getElementById('seekseven').value = seeklifeStr;
    }

    //3.seekcompanion
    document.getElementById('seekcompanion').addEventListener('change', seekcompanionChange);
    async function seekcompanionChange(e){
        e.preventDefault();

        var seekcompanionStr = document.getElementById('seekcompanion').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seekcompanionStr;
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('seeknine').value = seekcompanionStr;
        //assign values to input in getstarter seven
        document.getElementById('seekseven').value = seekcompanionStr;
    }

    //4.seektravel
    document.getElementById('seektravel').addEventListener('change', seektravelChange);
    async function seektravelChange(e){
        e.preventDefault();

        var seektravelStr = document.getElementById('seektravel').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seektravelStr;
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('seeknine').value = seektravelStr;
        //assign values to input in getstarter seven
        document.getElementById('seekseven').value = seektravelStr;
    }

    //5.seekonline
    document.getElementById('seekonline').addEventListener('change', seekonlineChange);
    async function seekonlineChange(e){
        e.preventDefault();

        var seekonlineStr = document.getElementById('seekonline').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seekonlineStr;
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('seeknine').value = seekonlineStr;
        //assign values to input in getstarter seven
        document.getElementById('seekseven').value = seekonlineStr;
    }

    //6.seekonline
    document.getElementById('seekflirting').addEventListener('change', seekflirtingChange);
    async function seekflirtingChange(e){
        e.preventDefault();

        var seekflirtingStr = document.getElementById('seekflirting').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seekflirtingStr;
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seekonline').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('seeknine').value = seekflirtingStr;
        //assign values to input in getstarter seven
        document.getElementById('seekseven').value = seekflirtingStr;
    }

    //starterfive
    //1.preferindia
    document.getElementById('preferindia').addEventListener('change', preferindiaChange);
    async function preferindiaChange(e){
        e.preventDefault();

        var preferindiaStr = document.getElementById('preferindia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferindiaStr;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = preferindiaStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = preferindiaStr;
    }

    //1.preferpakistan
    document.getElementById('preferpakistan').addEventListener('change', preferpakistanChange);
    async function preferpakistanChange(e){
        e.preventDefault();

        var preferpakistanStr = document.getElementById('preferpakistan').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferpakistanStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = preferpakistanStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = preferpakistanStr;
    }

    //1.preferbengali
    document.getElementById('preferbengali').addEventListener('change', preferbengaliChange);
    async function preferbengaliChange(e){
        e.preventDefault();

        var preferbengaliStr = document.getElementById('preferbengali').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferbengaliStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = preferbengaliStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = preferbengaliStr;
    }

    //1.preferafghan
    document.getElementById('preferafghan').addEventListener('change', preferafghanChange);
    async function preferafghanChange(e){
        e.preventDefault();

        var preferafghanStr = document.getElementById('preferafghan').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferafghanStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = preferafghanStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = preferafghanStr;
    }

    //1.preferindonesia
    document.getElementById('preferindonesia').addEventListener('change', preferindonesiaChange);
    async function preferindonesiaChange(e){
        e.preventDefault();

        var preferindonesiaStr = document.getElementById('preferindonesia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferindonesiaStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = preferindonesiaStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = preferindonesiaStr;
    }

    //1.preferiran
    document.getElementById('preferiran').addEventListener('change', preferiranChange);
    async function preferiranChange(e){
        e.preventDefault();

        var preferiranStr = document.getElementById('preferiran').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferiranStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = preferiranStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = preferiranStr;
    }

    //1.preferturkey
    document.getElementById('preferturkey').addEventListener('change', preferturkeyChange);
    async function preferturkeyChange(e){
        e.preventDefault();

        var preferturkeyStr = document.getElementById('preferturkey').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferturkeyStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = preferturkeyStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = preferturkeyStr;
    }

    //1.prefermalaysia
    document.getElementById('prefermalaysia').addEventListener('change', prefermalaysiaChange);
    async function prefermalaysiaChange(e){
        e.preventDefault();

        var prefermalaysiaStr = document.getElementById('prefermalaysia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = prefermalaysiaStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = prefermalaysiaStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = prefermalaysiaStr;
    }

    //starterfiveman
    //1.manpreferindia
    document.getElementById('manpreferindia').addEventListener('change', manpreferindiaChange);
    async function manpreferindiaChange(e){
        e.preventDefault();

        var manpreferindiaStr = document.getElementById('manpreferindia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferindiaStr;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = manpreferindiaStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = manpreferindiaStr;
    }

    //1.manpreferpakistan
    document.getElementById('manpreferpakistan').addEventListener('change', manpreferpakistanChange);
    async function manpreferpakistanChange(e){
        e.preventDefault();

        var manpreferpakistanStr = document.getElementById('manpreferpakistan').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferpakistanStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = manpreferpakistanStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = manpreferpakistanStr;
    }

    //1.manpreferbengali
    document.getElementById('manpreferbengali').addEventListener('change', manpreferbengaliChange);
    async function manpreferbengaliChange(e){
        e.preventDefault();

        var manpreferbengaliStr = document.getElementById('manpreferbengali').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferbengaliStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = manpreferbengaliStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = manpreferbengaliStr;
    }

    //1.manpreferafghan
    document.getElementById('manpreferafghan').addEventListener('change', manpreferafghanChange);
    async function manpreferafghanChange(e){
        e.preventDefault();

        var manpreferafghanStr = document.getElementById('manpreferafghan').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferafghanStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = manpreferafghanStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = manpreferafghanStr;
    }

    //1.manpreferindonesia
    document.getElementById('manpreferindonesia').addEventListener('change', manpreferindonesiaChange);
    async function manpreferindonesiaChange(e){
        e.preventDefault();

        var manpreferindonesiaStr = document.getElementById('manpreferindonesia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferindonesiaStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = manpreferindonesiaStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = manpreferindonesiaStr;
    }

    //1.manpreferiran
    document.getElementById('manpreferiran').addEventListener('change', manpreferiranChange);
    async function manpreferiranChange(e){
        e.preventDefault();

        var manpreferiranStr = document.getElementById('manpreferiran').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferiranStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = manpreferiranStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = manpreferiranStr;
    }

    //1.manpreferturkey
    document.getElementById('manpreferturkey').addEventListener('change', manpreferturkeyChange);
    async function manpreferturkeyChange(e){
        e.preventDefault();

        var manpreferturkeyStr = document.getElementById('manpreferturkey').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferturkeyStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = manpreferturkeyStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = manpreferturkeyStr;
    }

    //1.manprefermalaysia
    document.getElementById('manprefermalaysia').addEventListener('change', manprefermalaysiaChange);
    async function manprefermalaysiaChange(e){
        e.preventDefault();

        var manprefermalaysiaStr = document.getElementById('manprefermalaysia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manprefermalaysiaStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('prefernine').value = manprefermalaysiaStr;
        //assign values to input in getstarter seven
        document.getElementById('preferseven').value = manprefermalaysiaStr;
    }

    //startersix
    //1.lifetravel
    document.getElementById('lifetravel').addEventListener('change', lifetravelChange);
    async function lifetravelChange(e){
        e.preventDefault();

        var lifetravelStr = document.getElementById('lifetravel').value;
        var lifeStr = document.getElementById('life');
        lifeStr.innerText = lifetravelStr;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('lifenine').value = lifetravelStr;
    }

    document.getElementById('lifenature').addEventListener('change', lifenatureChange);
    async function lifenatureChange(e){
        e.preventDefault();

        var lifenatureStr = document.getElementById('lifenature').value;
        var lifeStr = document.getElementById('life');
        lifeStr.innerText = lifenatureStr;
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('lifenine').value = lifenatureStr;
    }

    document.getElementById('lifesport').addEventListener('change', lifesportChange);
    async function lifesportChange(e){
        e.preventDefault();

        var lifesportStr = document.getElementById('lifesport').value;
        var lifeStr = document.getElementById('life');
        lifeStr.innerText = lifesportStr;
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('lifenine').value = lifesportStr;
    }

    document.getElementById('lifebeach').addEventListener('change', lifebeachChange);
    async function lifebeachChange(e){
        e.preventDefault();

        var lifebeachStr = document.getElementById('lifebeach').value;
        var lifeStr = document.getElementById('life');
        lifeStr.innerText = lifebeachStr;
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('lifenine').value = lifebeachStr;
    }

    //starterseven
    //1.texte
    document.getElementById('texte').addEventListener('change', texteChange);
    async function texteChange(e){
        e.preventDefault();

        var texteStr = document.getElementById('texte').value;
        var contactStr = document.getElementById('contact');
        contactStr.innerText = texteStr;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('contactnine').value = texteStr;
    }

    //1.meeting
    document.getElementById('meeting').addEventListener('change', meetingChange);
    async function meetingChange(e){
        e.preventDefault();

        var meetingStr = document.getElementById('meeting').value;
        var contactStr = document.getElementById('contact');
        contactStr.innerText = meetingStr;
        document.getElementById('texte').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('contactnine').value = meetingStr;
    }

    //1.email
    document.getElementById('email').addEventListener('change', emailChange);
    async function emailChange(e){
        e.preventDefault();

        var emailStr = document.getElementById('email').value;
        var contactStr = document.getElementById('contact');
        contactStr.innerText = emailStr;
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('calling').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('contactnine').value = emailStr;
    }

    //1.calling
    document.getElementById('calling').addEventListener('change', callingChange);
    async function callingChange(e){
        e.preventDefault();

        var callingStr = document.getElementById('calling').value;
        var contactStr = document.getElementById('contact');
        contactStr.innerText = callingStr;
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        //assign values to input in getstarter nine
        document.getElementById('contactnine').value = callingStr;
    }

    //Set country you currently live
    document.getElementById('country').addEventListener('click', countryChange);
    async function countryChange(e){
        e.preventDefault();

        const column = ["United Kingdom","UK"];
        autocomplete(document.getElementById("country"), column);
    }

    //Set Location/city you currently live
    document.getElementById('location').addEventListener('click', locationChange);
    async function locationChange(e){
        e.preventDefault();

        /*const column = ["United Kingdom","UK"];
        autocomplete(document.getElementById("location"), column);*/
    }

    //backstartphone
    document.getElementById('backstartphone').addEventListener('click', backstartphoneChange);
    async function backstartphoneChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxxxxxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //backstartlogin
    document.getElementById('backstartlogin').addEventListener('click', backstartloginChange);
    async function backstartloginChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxxxxxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstarttwo
    document.getElementById('backstarttwo').addEventListener('click', backstarttwoChange);
    async function backstarttwoChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxxxxxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartthree
    document.getElementById('backstartthree').addEventListener('click', backstartthreeChange);
    async function backstartthreeChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartfour
    document.getElementById('backstartfour').addEventListener('click', backstartfourChange);
    async function backstartfourChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartfive
    document.getElementById('backstartfive').addEventListener('click', backstartfiveChange);
    async function backstartfiveChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartfiveman
    document.getElementById('backstartfiveman').addEventListener('click', backstartfivemanChange);
    async function backstartfivemanChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartsix
    document.getElementById('backstartsix').addEventListener('click', backstartsixChange);
    async function backstartsixChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartseven
    document.getElementById('backstartseven').addEventListener('click', backstartsevenChange);
    async function backstartsevenChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartseven
    document.getElementById('backstartlist').addEventListener('click', backstartlistChange);
    async function backstartlistChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstarteight
    document.getElementById('backstarteight').addEventListener('click', backstarteightChange);
    async function backstarteightChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstarternine
    document.getElementById('backstartnine').addEventListener('click', backstartnineChange);
    async function backstartnineChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('starterlist').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }
});

/**implement autocomplete list of columns function */
function autocomplete(inp, arr){
    //the autocomplete function takes two arguments,
    //the text field element and an array of possible autocompleted values:
    var currentFocus;
    //execute a function when someone writes in the text field:
    inp.addEventListener("input", function(e) {
        var a, b, i, val = this.value;
        //close any already open lists of autocompleted values
        closeAllLists();
        if(!val){return false;}
        currentFocus = -1;
        //create a DIV element that will contain the items (values):
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        //append the DIV element as a child of the autocomplete container:
        this.parentNode.appendChild(a);
        //for each item in the array...
        for(i=0; i<arr.length; i++){
            //check if the item starts with the same letters as the text field value:
            if(arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()){
                //create a DIV element for each matching element:
                b = document.createElement("DIV");
                //make the matching letters bold:
                b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                b.innerHTML += arr[i].substr(val.length);
                //insert a input field that will hold the current array item's value:
                b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                //execute a function when someone clicks on the item value (DIV element):
                b.addEventListener("click", function(e) {
                    //insert the value for the autocomplete text field:
                    inp.value = this.getElementsByTagName("input")[0].value;
                    //close the list of autocompleted values,(or any other open lists of autocompleted values:
                    closeAllLists();
                });
                a.appendChild(b);
            }
        }
    });
    //execute a function presses a key on the keyboard:
    inp.addEventListener("keydown", function(e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode === 40) {
            //If the arrow DOWN key is pressed,
            //increase the currentFocus variable:
            currentFocus++;
            //and and make the current item more visible:
            addActive(x);
        } else if (e.keyCode === 38) { //up
            //If the arrow UP key is pressed,
            //decrease the currentFocus variable:
            currentFocus--;
            //and and make the current item more visible:
            addActive(x);
        } else if (e.keyCode === 13) {
            //If the ENTER key is pressed, prevent the form from being submitted,
            e.preventDefault();
            if (currentFocus > -1) {
              //and simulate a click on the "active" item:
              if (x) x[currentFocus].click();
            }
        }
    });
    function addActive(x) {
        //a function to classify an item as "active":
        if (!x) return false;
        //start by removing the "active" class on all items:
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        //add class "autocomplete-active":
        x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
        // function to remove the "active" class from all autocomplete items:
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove("autocomplete-active");
        }
    }
    function closeAllLists(elmnt) {
        //close all autocomplete lists in the document,
        //except the one passed as an argument:
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
          if (elmnt !== x[i] && elmnt !== inp) {
            x[i].parentNode.removeChild(x[i]);
          }
        }
    }
    //execute a function when someone clicks in the document:
    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
};

/*function getCurrentDate() {
    // Date object
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    // Current Date
    var date = yyyy + "-" + mm + "-" + dd;
    return date;
}*/