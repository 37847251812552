import styled from "styled-components";

export const StarterContainer = styled.div`
    border: 1px solid black;
    height:600px;
`;

export const TextTitle = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    color: red;
    text-align: center;
`;

export const TextTitlePhone = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    text-align: center;
`;

export const TextContent = styled.p`
    font-size: 2.2rem;
    color: red;
    text-align: center;
    font-family: Brush Script MT, Brush Script Std, cursive;
`;

export const ButtonStart = styled.button`
    border: none;
    color: white;
    display: flex;
    width: 350px;
    height: 50px;
    border-radius: 20px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: red;
    cursor: pointer;
`;

export const ButtonStartGoogle = styled.button`
    border: none;
    color: white;
    display: flex;
    width: 350px;
    height: 50px;
    border-radius: 20px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: blue;
    cursor: pointer;
`;

export const StarterImage = styled.img`
    width:40px;
    height:40px;
    border-radius:50%;
    object-fit:cover;
`;

export const FormScroller = styled.form`
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple red;
    scrollbar-width: thin;
    border-radius: 10px;
    background-color: white;
    opacity: 0.7;
`;

export const FormScrollerOne = styled.form`
    width: 100%;
    height: 460px;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple red;
    scrollbar-width: thin;
`;

export const TextNormal = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    color: blck;
    text-align: center;
`;

export const FormContent = styled.div`
    display: flex;
    align-items:center;
    justify-content:space-between;
    padding:10px;
    font-size: 16px;
`;

export const FormContentAvatar = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin:20px 0px;
    font-size: 16px;
`;

export const BackStart = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    cursor: touch;
`;

export const FormImageScroller = styled.form`
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple red;
    scrollbar-width: thin;
    border-radius: 10px;
    background-color: white;
    
`;

export const FormResult = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 36px;
`;

export const LargeInput = styled.input`
    width:300px;
    height:30px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:200px;
    }

    @media screen and (max-width: 768px){
        width:230px;
    }
`;

export const LargeSelect = styled.select`
    width:310px;
    height:35px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:210px;
    }

    @media screen and (max-width: 768px){
        width:240px;
    }
`;

export const TermsLabel = styled.span`
    width: 100%;
    font-size: 0.9rem;
    color:#eb1b1b;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
`;

export const TermsLabelPhone = styled.span`
    width: 100%;
    font-size: 0.9rem;
    color:red;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
    margin:0;
`;

export const TextLogin = styled.span`
    width: 100%;
    font-size: 0.9rem;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
`;

export const TextArea = styled.textarea`
   width: 310px;
   height: 100px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
`;