import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import {AlertModal} from '../../helperfunctions/Helper';

import { 
    StarterContainer, 
    FormScrollerOne,
    TextLogin, 
    LargeInput,
    BackStart,
    ButtonStart, 
    TextArea, 
} from '../StarterStyles';

import { useState, useRef, } from "react";
import Axios from 'axios';
//import {getPosts} from '../../lib';
//import {postLoginGet} from '../../lib';

function StarterLogin() {

    //alert
    const elementOutId = "modaloutalertstarterlogin";
    const elementDropId = "backdropoutalertstarterlogin";
    const buttonHandler = "ouiHandlerAlertStarterLoginBtn";

    const loginPhoneNumberRef = useRef(null);
    const loginPasswordRef = useRef(null);
    const idReplyRef = useRef(null);
    const idStarterRef = useRef(null);
    const phoneReplyRef = useRef(null);
    const contactReplyRef = useRef(null);
    const idProfileRef = useRef(null);
    const textAreaProfileYouRef = useRef(null);
    const textAreaProfileSoulemateRef = useRef(null);

    //create list of user login data
    const [userList, setUserList] = useState([]);
    //show reply data
    const [userReply, setUserReply] = useState([]);
    //show profile data
    const [userProfile, setUserProfile] = useState([]);

    async function userLoginStarter(e){
        e.preventDefault();

        //declare DOM elements properties
        const resultatreply = document.getElementById('resultatreply');
        const resultatprofile = document.getElementById('resultatprofile');

        //check if input empty
        const validPhoneNumber = loginPhoneNumberRef.current.value;
        const validPawwordDetail = loginPasswordRef.current.value;
        var resultat = '';
        var resultat1 = '';
        var resultat2 = '';
        var resultat3 = '';

        if(!validPhoneNumber){
            const pTextePhoneNumber = "Enter your Phone Number";
            AlertModal(elementOutId, elementDropId, pTextePhoneNumber, buttonHandler);
        }else if(!validPawwordDetail){
            const pTextePassword = "Enter your Password";
            AlertModal(elementOutId, elementDropId, pTextePassword, buttonHandler);
        }else{
            const loginPhoneNumber = loginPhoneNumberRef.current.value;
            const loginPassword = loginPasswordRef.current.value;

            await Axios.post('https://rishtaapi.carions.net/login/detail', {
                loginPhoneNumber:loginPhoneNumber,
                loginPassword:loginPassword,
            }).then((response) => {
                resultat = response.data.length;
                if(resultat === 0){
                    const pTexteDetails = "Invalid details or press Back to Start and Get Start Now Button";
                    AlertModal(elementOutId, elementDropId, pTexteDetails, buttonHandler);
                }else{
                    Axios.post('https://rishtaapi.carions.net/login/get', {
                        //withCredentials: true,
                        loginPhoneNumber:loginPhoneNumber,
                        loginPassword:loginPassword,
                    }).then((response1) => {
                        //console.log(response1.data)
                        resultat1 = response1.data.length;
                        if(resultat1 === 0){
                            Axios.post('https://rishtaapi.carions.net/login/get/reply', {
                                //withCredentials: true,
                                loginPhoneNumber:loginPhoneNumber,
                                loginPassword:loginPassword,
                            }).then((response2) => {
                                resultat2 = response2.data.length;
                                if(resultat2 === 0){
                                    Axios.post('https://rishtaapi.carions.net/login/get/profile', {
                                        //withCredentials: true,
                                        loginPhoneNumber:loginPhoneNumber,
                                        loginPassword:loginPassword,
                                    }).then((response3) => {
                                        //console.log(response3);//cancel before deploying
                                        resultat3 = response3.data.length;

                                        if(resultat3 === 0){
                                            const pTexteInfo = "Thanks. You have completed the step and will be contacted by phone or notification to the next step.";
                                            AlertModal(elementOutId, elementDropId, pTexteInfo, buttonHandler);
                                        }else{
                                            //console.log(response3);//cancel before deploying
                                            //resultat3 = response3.data.length;
                                            resultatreply.style.display = 'none';
                                            resultatprofile.style.display = 'block';
                                            setUserList(response3.data);
                                        }
                                    })
                                }else{
                                    //console.log(response2.data);//cancel before deploying
                                    resultatreply.style.display = 'block';
                                    resultatprofile.style.display = 'none';
                                    resultat1 = response2.data.length;
                                    setUserList(response2.data);
                                }
                            })
                        }else{
                            //console.log(response1.data);//cancel before deploying
                            resultatreply.style.display = 'block';
                            resultatprofile.style.display = 'none';
                            resultat1 = response1.data.length;
                            setUserList(response1.data);
                        }
                    });//
                }
            });
        }
    };

    async function idReplyHandler(e){
        e.preventDefault();

        //declare DOM elements properties
        const reply = document.getElementById('reply');
        const profile = document.getElementById('profile');

        const loginPhoneNumber = document.getElementById('loginphonenumber').value;
        const loginPassword = document.getElementById('loginpassword').value;
        //console.log(loginPhoneNumber);//cancel before deploying
        //console.log(loginPassword);//cancel before deploying

        await Axios.post('https://rishtaapi.carions.net/reply', {
            //withCredentials: true,
            loginPhoneNumber:loginPhoneNumber,
            loginPassword:loginPassword,
        }).then((response4) => {
            //console.log(response4.data);//cancel before deploying
            reply.style.display = 'block';
            profile.style.display = 'none';
            setUserReply(response4.data);
        })

    }

    async function idProfileHandler(e){
        e.preventDefault();

        //declare DOM elements properties
        const reply = document.getElementById('reply');
        const profile = document.getElementById('profile');

        const loginPhoneNumber = document.getElementById('loginphonenumber').value;
        const loginPassword = document.getElementById('loginpassword').value;
        //console.log(loginPhoneNumber);//cancel before deploying
        //console.log(loginPassword);//cancel before deploying

        await Axios.post('https://rishtaapi.carions.net/profile', {
            //withCredentials: true,
            loginPhoneNumber:loginPhoneNumber,
            loginPassword:loginPassword,
        }).then((response5) => {
            //console.log(response5.data);//cancel before deploying
            profile.style.display = 'block';
            reply.style.display = 'none';
            setUserProfile(response5.data);
        })
    }

    async function confirmReplyHandler(e){
        e.preventDefault();

        //declare DOM elements properties
        const landing = document.getElementById('landing');
        const starter = document.getElementById('starter');
        const starterone = document.getElementById('starterone');
        const starterphone = document.getElementById('starterphone');
        const starterlogin = document.getElementById('starterlogin');
        const startertwo = document.getElementById('startertwo');
        const starterthree = document.getElementById('starterthree');
        const starterfour = document.getElementById('starterfour');
        const starterfive = document.getElementById('starterfive');
        const starterfiveman = document.getElementById('starterfiveman');
        const startersix = document.getElementById('startersix');
        const starterseven = document.getElementById('starterseven');
        const starterlist = document.getElementById('starterlist');
        const startereight = document.getElementById('startereight');
        const starternine = document.getElementById('starternine');
        const terme = document.getElementById('terme');
        const feedback = document.getElementById('feedback');

        const idReply = idReplyRef.current.value;
        const idStarter = idStarterRef.current.value;

        await Axios.post("https://rishtaapi.carions.net/confirm/reply", {
            //withCredentials: true,
            idReply:idReply,
            idStarter:idStarter,
        }).then(() => {
            const pTexteConfirmed = "You've successfully confirmed your contacts";
            AlertModal(elementOutId, elementDropId, pTexteConfirmed, buttonHandler);
            landing.style.display = 'none';
            starter.style.display = 'block';
            starterone.style.display = 'block';
            starterphone.style.display = 'none';
            starterlogin.style.display = 'none';
            startertwo.style.display = 'none';
            starterthree.style.display = 'none';
            starterfour.style.display = 'none';
            starterfive.style.display = 'none';
            starterfiveman.style.display = 'none';
            startersix.style.display = 'none';
            starterseven.style.display = 'none';
            starterlist.style.display = 'none';
            startereight.style.display = 'none';
            starternine.style.display = 'none';
            terme.style.display = 'none';
            feedback.style.display = 'none';
        });
    }

    async function confirmProfileHandler(e){
        e.preventDefault();

        //declare DOM elements properties
        const landing = document.getElementById('landing');
        const starter = document.getElementById('starter');
        const starterone = document.getElementById('starterone');
        const starterphone = document.getElementById('starterphone');
        const starterlogin = document.getElementById('starterlogin');
        const startertwo = document.getElementById('startertwo');
        const starterthree = document.getElementById('starterthree');
        const starterfour = document.getElementById('starterfour');
        const starterfive = document.getElementById('starterfive');
        const starterfiveman = document.getElementById('starterfiveman');
        const startersix = document.getElementById('startersix');
        const starterseven = document.getElementById('starterseven');
        const starterlist = document.getElementById('starterlist');
        const startereight = document.getElementById('startereight');
        const starternine = document.getElementById('starternine');
        const terme = document.getElementById('terme');
        const feedback = document.getElementById('feedback');

        //const idProfile = idProfileRef.current.value;
        const validTextAreaProfileYou = textAreaProfileYouRef.current.value;
        const validTextAreaProfileSoulemate = textAreaProfileSoulemateRef.current.value;
        //console.log(idProfile);//cancel before deploying
        //console.log(validTextAreaProfileYou);//cancel before deploying
        //console.log(validTextAreaProfileSoulemate);//cancel before deploying

        if(!validTextAreaProfileYou){
            const pTexteYou = "Write Something About You";
            AlertModal(elementOutId, elementDropId, pTexteYou, buttonHandler);
        }else if(!validTextAreaProfileSoulemate){
            const pTexteSoulmate = "Write something about Your futur soulemate";
            AlertModal(elementOutId, elementDropId, pTexteSoulmate, buttonHandler);
        }else{
            const idProfile = idProfileRef.current.value;
            const textAreaProfileYou = textAreaProfileYouRef.current.value;
            const textAreaProfileSoulemate = textAreaProfileSoulemateRef.current.value;

            Axios.post("https://rishtaapi.carions.net/update/profile", {
                //withCredentials: true,
                idProfile:idProfile,
                textAreaProfileYou:textAreaProfileYou,
                textAreaProfileSoulemate:textAreaProfileSoulemate,
            }).then(() => {
                const pTexteAppointment = "Thanks. You'll be contacted ASAP to arrange an appointment";
                AlertModal(elementOutId, elementDropId, pTexteAppointment, buttonHandler);
                landing.style.display = 'none';
                starter.style.display = 'block';
                starterone.style.display = 'block';
                starterphone.style.display = 'none';
                starterlogin.style.display = 'none';
                startertwo.style.display = 'none';
                starterthree.style.display = 'none';
                starterfour.style.display = 'none';
                starterfive.style.display = 'none';
                starterfiveman.style.display = 'none';
                startersix.style.display = 'none';
                starterseven.style.display = 'none';
                starterlist.style.display = 'none';
                startereight.style.display = 'none';
                starternine.style.display = 'none';
                terme.style.display = 'none';
                feedback.style.display = 'none';
            });
        }
    }

    return ( 
        <StarterContainer style={FontEcran} id="starterlogin">
            <FormScrollerOne>
                <br/>
                <TextLogin>Phone number</TextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeInput type="number" maxLength="11" id="loginphonenumber" ref={loginPhoneNumberRef} placeholder='UK phone number(11 digits)' />
                </div>
                <br/>
                <TextLogin>Password</TextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeInput type="text" id="loginpassword" ref={loginPasswordRef} placeholder='Password' />
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterlogin" onClick={userLoginStarter}>Login</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartlogin">Back to start</button>
                </BackStart>
                <br/>
                <div id="resultatreply">
                    {userList.map((val, i) => {
                        return(
                            <div key={i} className="resultatlist">
                                
                                <button onClick={idReplyHandler} className="resultlistbutton">Click to reply {val.username_starter}</button>
                            </div>  
                        )
                    })}
                </div>
                <br/>
                <div id="reply">
                    {userReply.map((valreply, j) => {
                        return(
                            <div className="resultatlist" key={j}>
                                <div>
                                    <div style={{display:'none'}}>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.id_reply} ref={idReplyRef} />
                                        </div>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.id_starter} ref={idStarterRef} />
                                        </div>
                                    </div>
                                    <div>
                                        <h1>{valreply.content_reply}</h1>
                                    </div>
                                    <br/>
                                    <label style={{display:'block',textAlign:'center'}}>Phone Number</label>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <LargeInput type="text" readOnly value={valreply.phone_starter} ref={phoneReplyRef} />
                                    </div>
                                    <br/>
                                    <label style={{display:'block',textAlign:'center'}}>Chat Channel</label>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <LargeInput type="text" readOnly value={valreply.contact_starter} ref={contactReplyRef} />
                                    </div>
                                    <br/>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <ButtonStart onClick={confirmReplyHandler}>Confirm</ButtonStart>
                                    </div>
                                    <br/><br/>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <br/>
                <div id="resultatprofile">
                    {userList.map((val, j) => {
                        return(
                            <div key={j} className="resultatlist">
                                <button onClick={idProfileHandler} className="resultlistbutton">Clik profile {val.username_starter}</button>
                            </div>  
                        )
                    })}
                </div>
                
                
                <div id="profile">
                    {userProfile.map((valreply, j) => {
                        return(
                            <div className="resultatlist" key={j}>
                                <div>
                                    <div style={{display:'none'}}>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.id_profile} ref={idProfileRef} />
                                        </div>
                                    </div>
                                    <div>
                                        <h1>{valreply.content_profile}</h1>
                                    </div>
                                    <br/>
                                    <label style={{display:'block',textAlign:'center'}}>About You</label>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <TextArea ref={textAreaProfileYouRef}></TextArea>
                                    </div>
                                    <br/>
                                    <label style={{display:'block',textAlign:'center'}}>Describe Your futur soulemate</label>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <TextArea ref={textAreaProfileSoulemateRef}></TextArea>
                                    </div>
                                    <br/>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <ButtonStart onClick={confirmProfileHandler}>Send Profile</ButtonStart>
                                    </div>
                                    <br/><br/>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div id="modaloutstarterlogin"></div>
                <div id="backdropoutstarterlogin"></div>

                <div id="modaloutalertstarterlogin"></div>
                <div id="backdropoutalertstarterlogin"></div>
            </FormScrollerOne>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterLogin;