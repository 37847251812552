import styled from "styled-components";

export const FooterContainer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(255, 0, 128);
    color: white;
    text-align: center;
`;

export const FooterBaseContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const FooterLabel = styled.div`
    font-size: 0.9rem;
    margin-left: 250px;

    @media screen and (max-width: 640px){
        margin-left: 30px;
    }

    @media screen and (max-width: 768px){
        margin-left: 40px;
    }
`;

export const FooterImage = styled.div`
    font-size: 1.4rem;
    margin-left: 250px;
    cursor: pointer;

    @media screen and (max-width: 640px){
        margin-left: 30px;
    }

    @media screen and (max-width: 768px){
        margin-left: 40px;
    }
`;

export const TermeScroller = styled.div`
    height: 400px;
    overflow-y: scroll;
`;

export const FooterTitle = styled.span`
   font-size: 20px;
   font-weight: 600;
   color: red;
   margin-left: 15px;
`;

export const FooterUnderTitle = styled.span`
    font-size: 12px;
    color: gray;
    margin-left: 15px;
`;

export const FooterIntro = styled.p`
    font-size: 15px;
    margin-left: 15px;
`;

export const FooterSubTitle = styled.span`
    font-size: 18px;
    font-weight: 600;
    margin-left: 15px;
`;

export const TextLogin = styled.span`
    width: 100%;
    font-size: 0.9rem;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
`;

export const TextArea = styled.textarea`
   width: 310px;
   height: 100px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
`;

export const LargeSelect = styled.select`
    width:310px;
    height:35px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:210px;
    }

    @media screen and (max-width: 768px){
        width:240px;
    }
`;

export const ButtonStart = styled.button`
    border: none;
    color: white;
    display: flex;
    width: 350px;
    height: 50px;
    border-radius: 20px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: red;
    cursor: pointer;
`;

export const BackStart = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
`;