import React from "react";
import image from '../circle-heart.png';
import './accueil.css';

function Accueil() {
    function anneeDebut(){
        var currentYear = new Date().getFullYear();
        return currentYear;
    }
    return ( 
        <div id="accueil">
            <div className="accueil">
                <img className="image_logo" src={image} alt='logo' />
                <h5>{anneeDebut()}</h5>
                <label>RISHTA</label>
                <button id="login">Login</button>
                <div style={{display:'none'}}>
                    <label id="sexe"></label>
                    <label id="sexefor"></label>
                    <label id="age"></label>
                    <label id="seek"></label>
                    <label id="prefer"></label>
                    <label id="life"></label>
                    <label id="contact"></label>
                </div>
            </div>
        </div>
    );
}

export default Accueil;