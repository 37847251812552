import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import { 
    StarterContainer, 
    //TextTitle, 
    //TextContent, 
    ButtonStart,  
    FormScroller,
    TextNormal, 
    FormContent, 
    BackStart,  
} from '../StarterStyles';

import { useRef, /*useEffect, useState*/ } from "react";
import Axios from 'axios';

function StarterSeven() {
    const sexesevenRef = useRef(null);
    const sexeforsevenRef = useRef(null);
    const seeksevenRef = useRef(null);
    const prefersevenRef = useRef(null);
    const resultatallsevenRef = useRef(null);
    const resultatonesevenRef = useRef(null);

    //const [resutatCountList, setResultatCountList] = useState([]);

    /*useEffect(() => {
        Axios.post("http://localhost:3001/api/select1").then((response) =>{
            setResultatCountList(response.data)
        })
    }, []);*/

    async function submitGetScoreStarter(e) {
        e.preventDefault();

        //declare DOM elements properties
        const landing = document.getElementById('landing');
        const starter = document.getElementById('starter');
        const starterone = document.getElementById('starterone');
        const starterphone = document.getElementById('starterphone');
        const starterlogin = document.getElementById('starterlogin');
        const startertwo = document.getElementById('startertwo');
        const starterthree = document.getElementById('starterthree');
        const starterfour = document.getElementById('starterfour');
        const starterfive = document.getElementById('starterfive');
        const starterfiveman = document.getElementById('starterfiveman');
        const startersix = document.getElementById('startersix');
        const starterseven = document.getElementById('starterseven');
        const starterlist = document.getElementById('starterlist');
        const startereight = document.getElementById('startereight');
        const starternine = document.getElementById('starternine');
        const terme = document.getElementById('terme');
        const feedback = document.getElementById('feedback');

        try {
            const sexeSeven = sexesevenRef.current.value;
            const sexeForSeven = sexeforsevenRef.current.value;
            const seekSeven = seeksevenRef.current.value;
            const preferSeven = prefersevenRef.current.value;
            //console.log(sexeSeven)
            //console.log(sexeForSeven)
            //console.log(seekSeven)
            //console.log(preferSeven)
            

            await Axios.post('https://rishtaapi.carions.net/allorigin/get', {
                //withCredentials: true,
                sexeSeven:sexeSeven,
                sexeForSeven:sexeForSeven,
                preferSeven:preferSeven,
            }).then((response1) => {
                //console.log(response1.data.length)
                resultatallsevenRef.current.value = response1.data.length;
            });

            await Axios.post('https://rishtaapi.carions.net/originseek/get', {
                //withCredentials: true,
                sexeSeven:sexeSeven,
                sexeForSeven:sexeForSeven,
                seekSeven:seekSeven,
                preferSeven:preferSeven,
            }).then((response2) => {
                //console.log(response2.data.length)
                resultatonesevenRef.current.value = response2.data.length;

            });

            landing.style.display = 'none';
            starter.style.display = 'block';
            starterone.style.display = 'none';
            starterphone.style.display = 'none';
            starterlogin.style.display = 'none';
            startertwo.style.display = 'none';
            starterthree.style.display = 'none';
            starterfour.style.display = 'none';
            starterfive.style.display = 'none';
            starterfiveman.style.display = 'none';
            startersix.style.display = 'none';
            starterseven.style.display = 'none';
            starterlist.style.display = 'block';
            startereight.style.display = 'none';
            starternine.style.display = 'none';
            terme.style.display = 'none';
            feedback.style.display = 'none';
            
        } catch (err) {
            if (err.response) {
                // The client was given an error response (5xx, 4xx)
                //console.log(err.response.data);
                //console.log(err.response.status);
                //console.log(err.response.headers);
            } else if (err.request) {
                // The client never received a response, and the request was never left
                //console.log(err.request);
            } else {
                // Anything else
                //console.log('Error', err.message);
            }
        }
    }
    return ( 
        <StarterContainer style={FontEcran} id="starterseven">
            <FormScroller>
                <br/>
                <TextNormal>Your prefered contact</TextNormal>
                <div style={{textAlign:'center', fontWeight:'bolder', fontFamily:'cursive', fontSize:'21px'}}>
                    <label>You can choose only one</label>
                </div>
                <br/>
                <FormContent>
                    <div>
                        <input type="checkbox" id="texte" name="texte" value="texte" />
                        <label htmlFor="texte">Chat/letters</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="meeting" name="meeting" value="meeting" />
                        <label htmlFor="meeting">Meetings</label><br/>
                    </div>
                </FormContent>
                <br/>
                <FormContent>
                    <div>
                        <input type="checkbox" id="email" name="email" value="email" />
                        <label htmlFor="email">Email</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="calling" name="calling" value="calling" />
                        <label htmlFor="calling">Calling</label><br/>
                    </div>
                </FormContent>
                <br/>
                <div style={{display:'none'}}>
                    <input type="text" id="sexeseven" name="sexeseven" ref={sexesevenRef} placeholder="sexe"/>
                    <input type="text" id="sexeforseven" name="sexeforseven" ref={sexeforsevenRef} placeholder="sexefor"/>
                    <input type="text" id="seekseven" name="seekseven" ref={seeksevenRef} placeholder="seek"/>
                    <input type="text" id="preferseven" name="preferseven" ref={prefersevenRef} placeholder="prefer"/>
                    <input type="text" id="resultatallseven" name="resultatallseven" ref={resultatallsevenRef} placeholder="all"/>
                    <input type="text" id="resultatoneseven" name="resultatoneseven" ref={resultatonesevenRef} placeholder="one"/>
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterseven" onClick={submitGetScoreStarter}>Next</ButtonStart>
                </div>

                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartseven">Back to start</button>
                </BackStart>

                /{/*{resutatCountList.map((val)=>{
                    return<div key={val.id_starter}>ID: {val.sexe_starter}</div>
                })}*/}

                <div id="modaloutalertstarterseven"></div>
                <div id="backdropoutalertstarterseven"></div>
            </FormScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterSeven;